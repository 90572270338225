.TimelineCard {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    cursor: pointer;
    margin-bottom : 1rem;
    &__title {
        font-size: 2.5rem;
        font-weight: 600;
        a {
            text-decoration: none;
            color: #333333;
        }
        &__icon {
            font-size: 1rem;
        }
    }
    &__description {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        text-align: justify;
    }
    &__link {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: #333333;
        text-decoration: none;
        &:hover {
            color: #333333;
            text-decoration: underline;
        }
    }
    &__logo {
        width: 25%;
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            // width: 100%;
            max-height: 10rem;
            max-width: 100%;
        }
    }
    &__content {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    &__location {
        font-size: 1.7rem;
        font-weight: 400;
    }
    &__period {
        font-size: 1.2rem;
        font-weight: 400;
    }
    &__line {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        & > * {
            margin-right: 1rem;
        }
    }
    &__position, &__graduationTitle {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 1000px) {
    .TimelineCard {
        flex-direction: column;
        width: calc(100% - 2rem);
        
        &__logo {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
        }
        &__content {
            width: 100%;
        }
        &__title {
            font-size: 1.8rem;
        }
        &__line {
            flex-direction: column;
            align-items: flex-start;
            & > * {
                margin-right: 0;
                margin-bottom: 0.5rem;
            }
        }
    }
}