.Skills {
    margin-top: 5rem;
    .title {
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 2rem;
        position: relative;
    }
    &__container {
        margin: 0 20%;
        &__list {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-left: -35px;
            margin-top: 2rem;
            &__title {
                position: relative;
                width: 0.5rem;
                margin: auto 35px;
                top: 0;
                transform: rotate(-90deg);
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-size: 1.5rem;
                white-space: pre;
                color: #515151;
            }
            &__chips {
                margin: 30px 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
            }
            &__chip {
                margin: 0.5rem;
                background-color: #f5f5f5;
                color: #515151;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .Skills {
        margin-top: 2rem;
        .title {
            font-size: 2rem;
        }
        &__container {
            margin: 0 10%;
        }
    }
}