.reverse-txt {
    direction: rtl;
    unicode-bidi: bidi-override;
}

.Footer {
    background-color: #f3f3f3;
    padding-bottom: 0.5rem ;
    &__copyright {
        text-align: center;
        font-size: 1rem;
        color: #515151;
        margin-top: 2rem;
    }
    &__container {
        margin: 0 20%;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 1000px) {
            margin: 0 5%;
            flex-direction: column;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                font-size: 2rem;
                font-weight: 500;
                color: #515151;
                margin-bottom: 0.5rem;
            }
            &__list {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 80%;
                &__item, a{
                    text-decoration: none;
                    color: #515151;
                    font-size: 1.7rem;
                    margin-bottom: 0.5rem;
                    
                }
            }
        }
    }
}