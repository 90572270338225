.Timeline {
    margin: 3rem 10rem;
    .title {
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 2rem;
        position: relative;
    }
}

@media screen and (max-width: 1000px) {
    .Timeline {
        margin: 3rem 1rem;
    }
}