.Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    background-image: url("~/public/images/wallpaper.avif");
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 75% 95%, 25% 95%, 0 100%);
    color: #333;
    text-align: center;
    padding-bottom: 2rem ;
    &__name {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 1rem;
        @media screen and (max-width: 1000px) {
            font-size: 3rem;
        }
    }
    &__title {
        font-size: 2rem;
        font-weight: 400;
        width: 70%;
        text-align: left;
        @media screen and (max-width: 1000px) {
            font-size: 1.7rem;
            margin-bottom: 0;
        }
    }
    &__desc {
        font-size: 1.5rem;
        font-weight: 400;
        width: 70%;
        text-align: justify;
        @media screen and (max-width: 1000px) {
            font-size: 1.2rem;
            width: 90%;
        }

    }
}
