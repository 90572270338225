.SkillChip {
    background: #fafafa;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    font-size: 1.5rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    &__icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        height: 3rem;
        width: 3rem;
        border-radius: 4px 0 0 4px;
        color: #fafafa;
    }
    &__name {
        display: flex;
        align-items: center;
        margin: 0 20px;
        font-size: 1.7rem;
        background: #fafafa;
        color: #5e5e5e;
        font-weight: 400;
        height: 3rem;
    }
}