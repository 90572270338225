$full-button-color: #0074cc;
$full-button-color-hover: #0a95ff;

.button {
    border: 1px solid transparent;
    border-radius: .5em;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: bold;
    padding: 1em 2em;
    transition: .3s;
    margin: 1rem;
    background-color: #1d6aad;
    border-color: #1d6aad;
    color: #fff;
    &:hover {
        background-color: #084070;
        border-color: #084070;
    }
}
